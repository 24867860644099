import React from "react"
import ArticleContentBody from "../../components/pages/ArticleDetails/ArticleContentBody"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const LogBookGuide = () => {
  return (
    <Layout>
      <Seo
        title="How to Use Cents per Kilometre Method in Logbook"
        description={`This guide provides comprehensive instructions for using the Cents per Kilometre method to claim car expenses for business travel.`}
      />

      {/* This slug is matched with articles in Content */}
      <ArticleContentBody slug={`logbook-guide`} />
    </Layout>
  )
}

export default LogBookGuide
